.minimizable-web-chat>button.maximize {
	border-radius: 50%;
	border: 2px solid #03356B;
	/* The image used */
	background-image: url("Images/caroline.png");
	/* Full height */
	height: 100%;
	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	bottom: 20px;
	box-shadow: 0 0 20px rgba(0, 0, 0, .2);
	color: White;
	font-size: 30px;
	height: 64px;
	outline: 0;
	padding: 15px;
	position: fixed;
	right: 20px;
	width: 64px;
	/* specifies the stack order of an element. make the element stand above the others  */
	z-index: 20;
}

.minimizable-web-chat>button.maximize>.green-dot {
	color: #00FF00;
	font-size: 12px;
	position: absolute;
	align-content: left;
	left: 3px;
	top: -1px;
}

.minimizable-web-chat>button.maximize>.message-dot {
	background-image: url("Images/message.png");
	position: absolute;
	align-content: right;
	right: -8px;
	top: -15px;
	height: 25px;
	width: 25px;
	font-size: 14px;
}

.minimizable-web-chat>button.maximize:focus:not(:active),
.minimizable-web-chat>button.maximize:hover:not(:active) {
	background-color: #069;
	border-color: #069;
}

.minimizable-web-chat>button.maximize:active {
	background-color: White;
	color: #03356B;
}

.minimizable-web-chat>.chat-box {
	background-color: rgba(255, 255, 255, .8);
	backdrop-filter: blur(10px);
	border-radius: 4px;
	box-shadow: 0 0 20px rgba(0, 0, 0, .2);
	border: 4px solid #03356B!important;
	bottom: 20px;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	min-width: 320px;
	position: fixed;
	top: 20px;
	width: 30%;
	/* specifies the stack order of an element. make the element stand above the others  */
	z-index: 20;
}

.minimizable-web-chat>.chat-box.left {
	background-color: #f2f6f9;
	left: 20px;
}

.minimizable-web-chat>.chat-box.right {
	background-color: #f2f6f9;
	right: 20px;
}

.minimizable-web-chat>.chat-box>header {
	background-color: #03356B !important;
	height: 40px;
	display: flex;
}

.minimizable-web-chat>.chat-box>header>.green-got-header {
	color: #00FF00;
	font-size: 10px !important;
	position: absolute;
	align-content: left;
	left: 10px;
	top: 12px;
}

.minimizable-web-chat>.chat-box>header>.filler {
	flex: 0 10000 100%;
}

.minimizable-web-chat>.chat-box>header>button {
	width: 40px;
	height: 40px;
	background-color: Transparent;
	border: 0;
	color: White;
	outline: 0;
}

.minimizable-web-chat>.chat-box>header>button:focus:not(:active),
.minimizable-web-chat>.chat-box>header>button:hover:not(:active) {
	color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat>.chat-box>header>button:focus:active {
	color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat>.chat-box>.react-web-chat {
	flex: 1;
}


/*Addition*/
/*empécher uppercase de zone input*/
.minimizable-web-chat .react-web-chat.web-chat .main form input {
    text-transform: none !important;
}
/*Bulles amÃ©liorÃ©es*/
/*Taille de texte*/
.bubble {
	font-size: 14px !important;
}
/*Bulle bot*/
.minimizable-web-chat .react-web-chat .webchat__row .bubble {
	border: 0 !important;
	border-top-right-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 20px !important;
}
/*Buller User*/
.minimizable-web-chat .react-web-chat .webchat__row .bubble.from-user {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 20px !important;
	border-top-left-radius: 20px !important;
	border-bottom-left-radius: 20px !important;
}
/*Boutons*/
.css-y5o52s > button, [data-css-y5o52s] > button {
	font-size: 12px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 0px !important;
}

.css-1m0i41h, [data-css-1m0i41h] {
	font-size: 12px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
}

.css-y5o52s > button:hover, [data-css-y5o52s] > button:hover {
	background-color: #0063B1 !important;
	color: white !important;
}


/*Encadrement bleu des boutons et retressissement des espaces*/
.minimizable-web-chat .react-web-chat .css-1wegtiu {
	padding: 0px 0 !important;
	background-color: #e3eef7 !important;
	text-align: left !important;
}
/*EnlÃ¨vement du padding gauche du premier bouton*/
.css-ovmwp4 > div > ul > li:first-child, [data-css-ovmwp4] > div > ul > li:first-child {
    padding-left: 0px !important;
}

/* Boutons des cartes */

.ac-pushButton.style-default {
    background-color: #A5A5A5 !important;
	color: white !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.ac-pushButton.style-default:hover, .ac-pushButton.style-default:active {
	background-color: #878787 !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.ac-pushButton.style-positive {
    background-color: #54AF4E !important;
	color: white !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.ac-pushButton.style-positive:hover, .ac-pushButton.style-positive:active {
	background-color: #489536 !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.ac-pushButton.style-destructive {
    background-color: #E50000 !important;
	color: white !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.ac-pushButton.style-destructive:hover, .ac-pushButton.style-destructive:active {
	background-color: #BF0000 !important;
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

/*Zones selectionnables dans cartes */
.ac-selectable:hover, .ac-selectable:active {
	background-color: #FFFF00 !important;
}

/*Bouton de lancement video*/
.ac-media-playButton
{
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(0,0,0,.6);
	border-radius: 50%;
	padding: 10px;
	background-color: rgba(255,255,255,.6);
}
.ac-media-playButton-arrow
{
	cursor: pointer;
    border-style: solid;
    transform: translate(1.2px, 0px);
    box-sizing: border-box;
    width: 27px;
    height: 30px;
    border-width: 15px 0px 15px 25px;
    border-color: transparent transparent transparent rgba(0,0,0,.6);
    margin-left: 5px;
}